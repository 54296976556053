<template>
    <div>
        <div>
            <div class="text-right">
                <v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
                    <v-icon>fas fa-times </v-icon>
                </v-btn>
            </div>
            <div class="text-center pb-5">
                <h3>Update uw titel</h3>
            </div>

            <label for="">Dialoogformulier titel</label>
            <v-text-field class="theme-input" solo title="Bevindingsnaam" v-model="data.title"></v-text-field>
        </div>
        <div class="text-center pt-5">
            <v-btn class="btn" color="themepurple" @click="submit" dark>Opslaan</v-btn>
        </div>
    </div>
</template>
<script>
import config from '../../../config';
import api from "../../../services/api";
export default {
    props: {
        dialog: Boolean,
        value: String,
        dialogueform: Number,
    },
    data: () => ({
        data: {
            title: "",
        }
    }),
    mounted() {
        this.data.title = this.value;
    },
    methods: {
        submit() {
            if (this.dialogueform != "nieuw") {
                api.patch(`${config.url.api}/v1/dialogueforms/${this.dialogueform}/lines`, this.data).then((res) => {
                    if (res.data.success) {
                        this.$toast.success('Titel succesvol geupdate.')
                        this.$emit('close-dialog')
                        this.$emit('updated', this.data.title)
                    } else {
                        this.$toast.error('Er ging iets mis bij het updaten van de titel.')
                    }
                }).catch(() => {
                    this.$toast.error('Er ging iets mis bij het updaten van de titel.')
                })
            } else {
                 this.$emit('close-dialog')
                this.$emit('updated', this.data.title)

            }
        }
    }
}

</script>

<style></style>
<template>
  <div class="option-table-container">
    <div class="option-table">
      <v-card elevation="0" :color="theme">
        <v-row class="pl-4 ma-0">
          <v-col cols="7" class="py-8">
            <h5>{{ title }}</h5>
          </v-col>
          <v-col cols="auto" class="ml-auto my-auto" style="width: 100px">
            <v-img
              class="ma-auto"
              width="56"
              src="../assets/table-plus-1.svg"
            ></v-img>
          </v-col>
          <v-col cols="auto" class="my-auto" style="width: 100px">
            <v-img
              class="ma-auto"
              width="56"
              src="../assets/table-plus-2.svg"
            ></v-img>
          </v-col>
          <v-col cols="auto" class="my-auto" style="width: 100px">
            <v-img
              class="ma-auto"
              width="56"
              src="../assets/table-plus-3.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: String,
    title: String,
  },
  data: () => ({}),
};
</script>

<style>
.option-table-container {
  overflow-x: scroll;
}
.option-table {
  border-radius: 4px !important;
  overflow: hidden;
  min-width: 100%;
}

.option-table h5 {
  font-weight: bold;
  color: #3f4a5e;
  font-size: 20px;
}

.option-table > .v-card {
  border-radius: 4px 4px 0px 0px !important;
}
.option-table .option-table-item:last-of-type {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}
</style>

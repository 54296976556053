<template>
  <div>
    <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
      color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0 sidebar-drawer">
      <v-list flat light>
        <v-list-item-group v-model="tab" light>
          <v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
            <v-list-item-title :key="i">
              {{ item.step }} {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-spacer></v-spacer>
      </v-list>

      <p class="bottom-text">{{ data.title }}</p>
    </v-navigation-drawer>
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="pa-0 ml-1 pt-0 pa-15">
      <div v-if="tab === 0">
        <v-row>
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="11">
                <h1 class="mb-5">{{ data.title }}</h1>
              </v-col>
              <v-col cols="1"> <v-btn v-if="edit" text elevation="0" @click="edittitledialog = true"> <v-icon
                    color="primary" small>
                    mdi-pencil
                  </v-icon></v-btn></v-col>
            </v-row>
            <p v-if="user.role === 'PM'">
             Dit periodieke dialoogformulier biedt een basis om het goede gesprek aan te gaan met de procesmanagers op het
            tactische niveau. Het is bedoeld voor de ontwikkellijn of de programmalijn. Deze dialoog geeft handvatten om de
            informatie uit de projecten te aggregeren naar het tactische niveau. Doen wij de goede dingen en doen we ze goed?
            Wat leren wij vanuit de projecten, waardoor we de komende periode ons werk anders gaan doen?
            </p>

            <div v-if="user.role === 'OG'">
              <p>
                Illo sed beatae maxime. In perferendis omnis. Error doloribus
                voluptas sed omnis vel aut laboriosam saepe est. Molestiae
                reprehenderit aut eius deleniti.
              </p>
              <p>Gestart: {{ startdate }}</p>
              <br />
              <h4 class="mb-3">Mensen die aan dit project hebben gewerkt</h4>
              <v-row>
                <v-col cols="12">
                  <v-list color="transparent" v-if="readyusers">
                    <v-list-item dense  v-for="(user, key) in persons" :key="key" class="px-0">
                      <v-list-item-content>
                        <p class="my-0">
                          <strong>{{
                            user.fullname.capitalize() + " - " + user.role.getrole()
                          }}</strong>

                        </p>

                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-skeleton-loader v-else type="list-item"></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col v-if="user.role === 'PU'" cols="12" md="7" class="text-right ml-auto">
            <v-btn class="text-white btn" color="themepurple">
              <v-icon left>fas fa-download</v-icon> Dialoog formulier lijnen Downloaden als
              PDF</v-btn>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 1">
        <v-row>
          <v-col cols="12" md="6">
            <h1 class="mb-10">Ambitie</h1>

            <label for="">Periode</label>
            <v-text-field v-model="data.ambition.period" class="theme-input" solo background-color="#F7EBD8"
              title="Periode"></v-text-field>

            <label for="">Ontwikkellijn</label>
            <v-text-field v-model="data.ambition.line" class="theme-input" solo background-color="#F7EBD8"
              title="Ontwikkellijn"></v-text-field>

            <label>Wat is de aanleiding van project?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo v-model="data.ambition.reason"
              title="Wat is de aanleiding van project?"></v-textarea>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 2">
        <v-row>
          <v-col cols="12" md="6">
            <v-btn class="ml-auto d-block" v-if="dialogueformindex > 0" fab small elevation="0" color="error"
              @click="removedialogueform(dialogueformindex)">
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
            <h2 class="mb-10">Dialoog formulier lijnen vanuit de projecten/activiteiten</h2>
            <label for="">Projectnaam</label>
            <v-text-field v-if="user.role == 'OG'"  background-color="#F7EBD8" class="theme-input" solo title="Project naam" readonly v-model="data.dialogueforms[dialogueformindex].project.title"></v-text-field>
            <v-select v-else v-model="data.dialogueforms[dialogueformindex].project" class="theme-input" solo
              background-color="#F7EBD8" :items="projects" return-object item-text="title"></v-select>
            <label for="">Hoe staat het ervoor met je project?</label>
            <Feedbacksmiley class="my-5" :edit="edit" @update="data.dialogueforms[dialogueformindex].feedback = $event"
              :val="data.dialogueforms[dialogueformindex].feedback" />

            <label>Korte duiding over de score</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.dialogueforms[dialogueformindex].feedback_explanation" title="Korte duiding over de score"></v-textarea>

            <label>Welke verwachtingen waren over het project ten aanzien van de
              bijdrage aan de ambities?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.dialogueforms[dialogueformindex].effort_ambition_contribution"
              title="Welke verwachtingen waren over het project ten aanzien van de bijdrage aan de ambities?"></v-textarea>

            <label>En hoe verhouden die zich tot de realiteit van de afgelopen 2
              maanden?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.dialogueforms[dialogueformindex].reality_check"
              title="En hoe verhouden die zich tot de realiteit van de afgelopen 2 maanden?"></v-textarea>

            <label>Successen en zorgen?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.dialogueforms[dialogueformindex].successes_and_worries" title="Successen en zorgen?"></v-textarea>

            <label>Leidt dat tot een interventie of koerswijziging? Zo ja,
              welke?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.dialogueforms[dialogueformindex].interventions"
              title="Leidt dat tot een interventie of koerswijziging? Zo ja, welke?"></v-textarea>
            <v-btn class="mx-auto d-block" fab small elevation="0" color="primary" @click="adddialogueform()">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            <p class="text-center mt-1">Nog een project toevoegen</p>
          </v-col>
          <v-col cols="12" md="1"></v-col>

          <v-col cols="12" md="5">
            <h5 class="mb-2">Projecten</h5>
            <v-card class="rounded-xl pa-4" elevation="0" outlined>
              <v-card-text>
                <ul class="projects-list pl-0">
                  <li v-for="(dialogueform, key) in data.dialogueforms" :key="key" @click="dialogueformindex = key">
                    <v-btn class="btn" color="transparent" elevation="0">{{ dialogueform.project.title }}</v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 3">
        <h2 class="mb-10">Tactisch</h2>

        <v-row>
          <v-col cols="12" md="9">
            <h4 class="mb-3">
              In welke mate dragen de ontwikkellijnen bij aan de meetpunten op
              de meetlat?
            </h4>

            <optiontable title="Perspectief van ouders en jeugdige versterken/verbeteren" theme="#F7EBD8">
              <optiontableitem theme="#F7EBD8" title="Het belang van de jeugdige staat voorop"
                @update="data.perspective_parents.intrest_paramount = $event"
                :val="data.perspective_parents.intrest_paramount"></optiontableitem>
              <optiontableitem theme="#F7EBD8" title="Jeugdigen en/of ouders worden actief betrokken"
                @update="data.perspective_parents.actively_involved = $event"
                :val="data.perspective_parents.actively_involved"></optiontableitem>
              <optiontableitem theme="#F7EBD8"
                title="Versterking van eigen kracht en verantwoordelijkheid van jeugdige en/of ouders"
                @update="data.perspective_parents.empowerment_power_responsibility = $event"
                :val="data.perspective_parents.empowerment_power_responsibility"></optiontableitem>


            </optiontable>
            <br />

            <optiontable title="Perspectief van de professionals" theme="#F7EBD8">
              <optiontableitem theme="#F7EBD8" title="Betere samenwerking: 1 gezin, 1 plan, 1 regisseur"
                @update="data.perspective_professional.collaboration_improvement = $event"
                :val="data.perspective_professional.collaboration_improvement"></optiontableitem>
              <optiontableitem theme="#F7EBD8" title="Minder regeldruk voor professionals"
                @update="data.perspective_professional.regulatory_burden = $event"
                :val="data.perspective_professional.regulatory_burden"></optiontableitem>
            </optiontable>

            <br />

            <optiontable title="Kostenbeheersing" theme="#F7EBD8">
              <optiontableitem theme="#F7EBD8" title="Meer doelmatige hulp (efficiënt/effectief)"
                @update="data.cost_regulation.effective_aid = $event" :val="data.cost_regulation.effective_aid">
              </optiontableitem>
              <optiontableitem theme="#F7EBD8"
                title="Geen onnodige jeugdhulp als ook het netwerk iets kan betekenen (normaliseren en ontzorgen)"
                @update="data.cost_regulation.unnecessary_aid = $event" :val="data.cost_regulation.unnecessary_aid">
              </optiontableitem>
            </optiontable>

            <br />

            <optiontable title="Verbeteren van kwaliteit van de jeugdhulp" theme="#F7EBD8">
              <optiontableitem theme="#F7EBD8" title="Jeugdhulp dichterbij (t)huis"
                @update="data.quality_improvement.closer_to_home = $event" :val="data.quality_improvement.closer_to_home">
              </optiontableitem>
              <optiontableitem theme="#F7EBD8" title="Juiste zorg op het juiste moment (ook voor de complexe doelgroep)"
                @update="data.quality_improvement.right_moment = $event" :val="data.quality_improvement.right_moment">
              </optiontableitem>
            </optiontable>
          </v-col>

          <v-col cols="12" md="6">
            <label>Zijn we met de goede dingen bezig?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.improvements.are_we_doing_good_things"
              title="Zijn we met de goede dingen bezig?"></v-textarea>

            <label for="">Ontbreken er zaken?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.improvements.are_we_missing_things" title="Ontbreken er zaken?"></v-textarea>

            <label for="">Hoe dragen de inspanningen bij aan de ambities?</label>
            <v-textarea background-color="#F7EBD8" class="theme-input" solo
              v-model="data.improvements.effort_ambition_contribution"
              title="Hoe dragen de inspanningen bij aan de ambities?"></v-textarea>
          </v-col>
        </v-row>

        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 4">
        <h2 class="mb-10">
          Voorgestelde aanbeveling ter bespreking in het team
        </h2>
        <v-row>
          <v-col cols="12" md="6">
            <label>Aanbeveling 1</label>
            <v-text-field background-color="#F7EBD8" class="theme-input" solo v-model="data.recommendations.first"
              title="Aanbeveling 1"></v-text-field>
            <label>Aanbeveling 2</label>
            <v-text-field background-color="#F7EBD8" class="theme-input" solo v-model="data.recommendations.second"
              title="Aanbeveling 2"></v-text-field>
            <label>Aanbeveling 3</label>
            <v-text-field background-color="#F7EBD8" class="theme-input" solo v-model="data.recommendations.third"
              title="Aanbeveling 3"></v-text-field>
          </v-col>
        </v-row>

        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <!-- Download / submit dialog -->
      <v-dialog v-model="nextdialog" content-class="theme-popup">
        <div class="text-right">
          <v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
            <v-icon>fas fa-times </v-icon>
          </v-btn>
        </div>
        <div v-if="(senddate = true)">
          <div class="text-center pb-5">
            <h3>Keuzes Keuzes Keuzes</h3>
          </div>
          <div class="text-center">
            <p>
              Illo sed beatae maxime. In perferendis omnis. Error doloribus
              voluptas sed omnis vel aut laboriosam saepe est.
            </p>
          </div>
          <div class="text-center pt-5">
            <v-btn class="btn text-primary mr-3" color="#fff">Downloaden</v-btn>
            <v-btn class="btn" color="themepurple" dark>Inleveren</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <h3>Verstuurd</h3>
          </div>
          <div class="text-center">
            <p>Document is verstuurd naar organisator.</p>
          </div>
        </div>
      </v-dialog>
      <v-dialog v-model="edittitledialog" content-class="theme-popup">
        <titleedit :dialogueform="id" v-model="data.title" @close-dialog="edittitledialog = false"
          @updated="getForms(); this.data.title = $event" />
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import optiontable from "@/components/optiontable.vue";
import optiontableitem from "@/components/optiontableitem.vue";
import Feedbacksmiley from "../../components/feedbacksmiley.vue";
import Titleedit from '@/components/dialogs/dialogueform/edittitle.vue';

import api from "../../services/api";
import config from '../../config';
import { mapGetters } from 'vuex'
export default {
  components: { optiontable, optiontableitem, Feedbacksmiley, Titleedit },
  data() {
    return {
      edit: false,
      sidebar: true,
      ready: true,
      readyusers: false,
      nextdialog: false,
      senddata: false,
      edittitledialog: false,
      id: "nieuw",
      menuitems: [
        {
          title: "Dialoogformulier lijnen",
        },
        {
          title: "1. Ambitie",
        },
        {
          title: "2. Bevindingen vanuit de projecten/activiteiten",
        },
        {
          title: "3. Tactisch",
        },
        {
          title: "4. Voorgestelde aanbevelingen ter bespreking in het team",
        },
      ],
      tab: 0,
      dialogueformindex: 0,
      startdate: "",
      persons: [
        {
          fullname: ""
        }
      ],
      projects: [],
      data: {
        title: "Dialoogformulier lijnen 01",
        ambition: {
          period: "",
          line: "",
          reason: "",
        },
        dialogueforms: [
          {
            project: "Projectnaam",
            feedback: "1",
            feedback_explanation: "",
            effort_ambition_contribution: "",
            reality_check: "",
            successes_and_worries: "",
            interventions: ""
          },
        ],
        perspective_parents: {
          intrest_paramount: "",
          actively_involved: "",
          empowerment_power_responsibility: "",
        },
        perspective_professional: {
          collaboration_improvement: "",
          regulatory_burden: "",
        },
        cost_regulation: {
          effective_aid: "",
          unnecessary_aid: "",
        },
        quality_improvement: {
          closer_to_home: "",
          right_moment: "",
        },
        improvements: {
          are_we_doing_good_things: "",
          are_we_missing_things: "",
          effort_ambition_contribution: ""
        },
        recommendations: {
          first: "",
          second: "",
          third: ""
        }
      },
    }
  },
  computed: {
    ...mapGetters({ user: 'getuser' }),
  },
  methods: {
    getFormUsers() {
      this.readyusers = false;
      api.get(`${config.url.api}/v1/dialogueforms/${this.$route.params.id}/users`).then((res) => {
        this.persons = res.data.data;
        this.readyusers = true;
      })
    },
    getProjects() {
      api.get(`${config.url.api}/v1/projects/list`).then((res) => {
        if (res.data.success) {
          this.projects = res.data.data;
          const urlParams = new URLSearchParams(window.location.search);
          var projects = urlParams.get('projects');
          if (projects != null) {
            projects = projects.split(",");
            this.data.dialogueforms = [];
            projects.forEach(p => {
              if (p !== "") {
                this.projects.forEach(project => {
                  if (project.id == parseInt(p)) {
                    this.adddialogueform(project);
                  }
                });
              }
            });
            this.dialogueformindex = 0;
          }
        }
      }).catch((err) => {
        switch (err.response.status) {
          default:
            this.$toast.error('Fout bij het ophalen van je projecten.')
            break;
        }
      })
    },

    getForms() {
      api.get(`${config.url.api}/v1/dialogueforms/${this.$route.params.id}/lines`).then((res) => {
        if (res.data.success) {
          this.data = res.data.data.data;
          this.data.title = res.data.data.title;
          this.edit = this.user.role == "PM" || this.user.role == "OG";
          this.ready = true;
          if (this.$route.params.id == undefined) {
            this.$router.push("/dialoogformulier-lijnen/" + this.$route.params.id)
          }
        } else {
          this.$router.push("/dialoogformulier-lijnen/" + this.$route.params.id)
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het dialoog formulier lijnen.')
            break;
        }
      })
    },
    submit(type) {
      var data = {
        data: this.data,
        type: type
      };
      var patchslug = this.$route.params.id === "nieuw" ? 0 : this.$route.params.id;
      api.patch(`${config.url.api}/v1/dialogueforms/${patchslug}/lines`, data).then((res) => {
        if (res.data.success) {
          //redirect to the newly created recource.
          switch (type) {
            case "concept":
              this.$toast.success('Concept dialoogformulier lijnen succesvol geupdate.');
              break;
            case "save":
              this.$toast.success('Dialoogformulier lijnen succesvol ingeleverd.');
              this.nextdialog = false;
              break;
          }

          if (patchslug == 0) {
            this.$router.push("/dialoogformulier-lijnen/" + res.data.slug)
          }

        } else {
          this.$toast.error('Er ging iets mis bij het opslaan van je dialoogformulier lijnen.')
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$toast.error('Je hebt geen toegang tot deze dialoogformulier lijnen.')
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
            break;
        }
      })
    },
    adddialogueform(project = undefined) {
      if(project == undefined){
        project = {title: `Project ${this.data.dialogueforms.length + 1}`}
      }
      this.data.dialogueforms.push({
        project: project,
        feedback: "1",
        feedback_explanation: "",
        effort_ambition_contribution: "",
        reality_check: "",
        successes_and_worries: "",
        interventions: ""
      })
      this.dialogueformindex = this.data.dialogueforms.length - 1;
    },
    removedialogueform(index) {
      this.data.dialogueforms.splice(index, 1);
      this.dialogueformindex = this.data.dialogueforms.length - 1;
    },

    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getProjects();
    if (this.$route.params.id != "nieuw") {
      this.getFormUsers();
      this.getForms();
    } else {

      this.edit = true;
    }
  },
};
</script>
<style>
.projects-list {
  list-style-type: none;
  font-size: 16px;
}
</style>

<template>
  <v-expansion-panel class="custom-accordion-item">
    <v-expansion-panel-header>
      <span v-html="title"> </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  components: {},

  props: {
    title: String,
  },
  data: () => ({}),
};
</script>

<style>
.custom-accordion-item {
  border: 1px solid;
  border-radius: 4px !important;
  margin-bottom: 10px;
}

.custom-accordion-item i {
  color: #3f4a5e !important;
  font-size: 38px !important;
  margin-right: -10px !important;
}
.custom-accordion-item p{
  margin-bottom: 0px;
}
</style>

<template>
  <div class="feedback-smiley">
    <v-radio-group hide-details v-model="feedback" row class="px-0 mt-0 pt-0 mx-auto d-flex" :readonly="!edit">
      <v-radio color="#9FC9AF" value="1">
        <template v-slot:label>
          <img src="../assets/smiley-green.svg" alt="" />
        </template>
      </v-radio>
      <v-radio color="#D89A3E" value="2">
        <template v-slot:label>
          <img src="../assets/smiley-orange.svg" alt="" />
        </template>
      </v-radio>
      <v-radio color="#D37287" value="3">
        <template v-slot:label>
          <img src="../assets/smiley-red.svg" alt="" />
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    edit: Boolean,
    val: String
  },
  watch: {
    feedback(val) {
      this.$emit("update", val);
    }
  },
  mounted() {
    this.feedback = this.val;
  },
  data: () => ({
    feedback: "1"
  }),

};
</script>

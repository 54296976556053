<template>
  <div class="option-table-item" :style="`  border-color: ${this.theme}!important`">
    <v-row class="pl-4 pt-0 ma-0">
      <v-col cols="7" class="py-4 my-auto">
        <p>{{ title }}</p>
      </v-col>
      <v-col cols="auto" class="ml-auto pa-0 mt-0">
        <v-radio-group
          hide-details
          v-model="selected"
          row
          class="px-0 mt-0 pt-0 mx-auto d-flex"
        >
          <v-radio
            :style="`border-color: ${this.theme}`"
            color="#c5dfcf"
            value="1"
          ></v-radio>
          <v-radio
            :style="`border-color: ${this.theme}`"
            color="#9fc9af"
            value="2"
          ></v-radio>
          <v-radio
            :style="`border-color: ${this.theme}`"
            color="#5f7969"
            value="3"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    theme: String,
    title: String,
    val: String,
  },
  watch: {
    selected(val) {
      this.$emit("update", val);
    }
  },
  mounted() {
    this.selected = this.val;
  },
  data: () => ({
    selected: "1"
  }),
};
</script>

<style>
.option-table-item {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
  border-bottom: 1px solid !important;
}

.option-table-item .v-radio {
  width: 100px;
  margin-right: 0px !important;
  border-left: 1px solid;
  border-bottom: 0px;
  border-top: 0px;
  min-height: 60px;
  height: 100%;
}

.option-table-item .v-radio .v-input--selection-controls__input {
  text-align: center !important;
  display: flex !important;
  margin: 0 auto !important;
}


.option-table-item p {
  font-size: 16px;
  color: #3f4a5e96;
  margin-bottom: 0px;
}
.v-input__slot {
  margin-bottom: 0px;
  height: 100%;
}
</style>
